import React from "react"
import Layout from "../components/layout"
import Meta from "../components/Meta"

const NotFoundPage = () => (
  <Layout>
    <Meta title="404 | OFFICIAL BUSINESS" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
